import './Menu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import './index.css';

import {SettingOutlined} from "@ant-design/icons";
import React from 'react';
import {Layout, Row, Space} from "antd";
import {Image} from 'react-bootstrap';
import {Col} from "antd/es";
import api from "./api";
import Auth from "./Auth";
import {CurrentUserContext} from "./contexts/CurrentUserContext";
import {
    About,
    ContactUsLink, ContactUsModal,
    CurrentUserAvatar,
    GuideButton,
    HomeLink,
    LogOut,
    OtherResources
} from "@digatex/digatex-ui-lib";

const FileDownload = require('js-file-download');

const sendEmail = (formData) => {
    return api.post('/email/contact', formData)
}

export class Menu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showContactForm: false,
            triedToSubmit: false,
            submitInProgress: false
        }

        this.formRef = React.createRef();
    }

    handleLogout = () => {
        Auth.logout();
        if (this.props.history) {
            this.props.history.replace(Auth.redirectToLogin());
        } else {
            window.location.href = Auth.redirectToLogin()
        }
    }

    render() {



        const mode = '-white'
        let title =  (window.location.href.includes('admin') || window.location.href.includes('users') || window.location.href.includes('dashboard')) ? 'Download quick reference guide for administrators' : 'Download quick reference guide';

        const user = this.context;

        return (
            <Row>
                <Col span={24}>
                <Layout.Header style={{padding: 10, display: 'flex', alignItems: 'center'}}>
                    <HomeLink/>
                    <a href='/settings'><SettingOutlined  style={{color: 'white', fontSize: '32px', marginTop: '20px', verticalAlign:'center', paddingLeft: 10}}/></a>

                    <div>
                        {this.props.logout !== false &&
                            <div>
                                {user.name() &&
                                    <div style={{display: "inline"}}>
                                        <div style={{display: "inline",paddingTop: -5, paddingLeft: 5}}>

                                            <a href='/search'><Image src={`/images/magnify${mode}.png`}/></a>
                                            <a href='/user'><Image src={`/images/account-circle-outline${mode}.png`}/></a>
                                        </div>
                                    </div>}
                    </div>}
                    </div>
                    {this.props.search}
                    <div style={{marginLeft: 'auto'}}>
                        {
                            <Row>
                                <Space size={"middle"}>
                                    <About/>
                                    <OtherResources/>
                                    <GuideButton module="inventory"/>
                                    <ContactUsLink show={() => this.setState({showContactForm: true})}/>
                                    <LogOut />
                                    <CurrentUserAvatar/>
                                </Space>
                            </Row>}
                    </div>

                </Layout.Header>
                <ContactUsModal show={this.state.showContactForm}
                                sendEmail={sendEmail}
                                close={() => this.setState({showContactForm: false})}/>
                </Col>
            </Row>
        );
    }
}

Menu.contextType = CurrentUserContext;


export default Menu;
